import React from 'react';
import Layout from '../../components/Layout';
import { withIntl } from '../../i18n';
import PricingFrame from '../../templates/Pricing/Page/PricingFrame';

function generateKey() {
  const timestamp = new Date().getTime();
  const randomComponent = Array.from({ length: 6 }, () => Math.random().toString(36).charAt(2)).join('');
  const randomStringId = `${timestamp}_${randomComponent}`;
  return randomStringId;
}

class Page extends React.PureComponent {
  render() {
    return (
      <Layout key={generateKey()} {...this.props} page={'product'}>
        <PricingFrame {...this.props} />
      </Layout>
    );
  }
}

export default withIntl(Page);
